/* eslint-disable camelcase */
import { get, compact, uniq } from 'lodash';
import DatadogHandler from 'utils/datadog';
import {
  classOpsService,
  userService,
  centreSerivce,
  childService,
  classService,
  MS_PHASE_2D_CLASS_OPERATIONS_ENDPOINTS,
  MS_PHASE_2B_CLASS_ACTIVITY_ENPOINT,
} from '../../../utils/msEndpointConstants';
import { DEFAULT_ERROR } from '../../../utils/constants';
import {
  retrieveListOrObject,
  retrieveDataMapper,
  retrieveObjectMapper,
} from '../../../utils';

const {
  LIST_ALL_BUS_ENTRIES,
  DELETE_BUS_ENTRY,
  CREATE_BUS_ROUTE,
  UPDATE_BUS_ROUTE,
  LIST_ALL_BUS_CHILDREN,
  GET_BUS_BY_IDS,
  CREATE_BUS_CHILD,
  DELETE_CHID_FROM_BUS,
  GET_UNASSIGN_CHILDREN,
} = MS_PHASE_2D_CLASS_OPERATIONS_ENDPOINTS.BUS;
const {
  USER: { GET_BY_IDS },
  CENTRE: { GET_CENTRE_BY_IDS },
  CLASS: { GET_CLASS_BY_IDS },
  CHILD: {
    GET_CHILD_BY_IDS,
    GET_CHILD_CURRENT_CLASS_BY_IDS,
    GET_LEVEL_BY_CHILD_IDS,
  },
} = MS_PHASE_2B_CLASS_ACTIVITY_ENPOINT;

export const transformListAllBusData = (
  busEntries = {},
  busDrivers = [],
  busAttendants = [],
  centres = []
) => {
  return {
    data: {
      listAllBusEntries: {
        totalCount: get(busEntries, 'totalCount', 0),
        data: get(busEntries, 'data', []).map(item => ({
          ID: get(item, 'bus.id'),
          direction: get(item, 'bus_entry.direction_string'),
          yearTime: get(item, 'bus_entry.year_time'),
          fkBus: get(item, 'bus_entry.fk_bus'),
          fkBusDriver: get(item, 'bus_entry.fk_bus_driver'),
          fkBusAttendant: get(item, 'bus_entry.fk_bus_attendant'),
          active: get(item, 'bus_entry.active'),
          status: get(item, 'bus_entry.status'),
          label: get(item, 'bus_entry.label'),
          busCompany: get(item, 'bus_entry.bus_company'),
          bus: {
            fkCentre: get(item, 'bus.fk_centre'),
            plateNumber: get(item, 'bus.plate_number'),
            centre: retrieveDataMapper(centres, get(item, 'bus.fk_centre'), [
              'id',
              'label',
            ]),
          },
          busUserByFkBusDriver: retrieveDataMapper(
            busDrivers,
            get(item, 'bus_entry.fk_bus_driver'),
            [
              'id',
              'firstname',
              'lastname',
              'mobile_phone',
              'mobile_phone_country_code',
              'passcode:login_pin',
            ]
          ),
          busUserByFkBusAttendant: retrieveDataMapper(
            busAttendants,
            get(item, 'bus_entry.fk_bus_attendant'),
            [
              'id',
              'firstname',
              'lastname',
              'mobile_phone',
              'mobile_phone_country_code',
              'passcode:login_pin',
            ]
          ),
        })),
      },
    },
  };
};

export const msListAllBusData = async (_, reqBody) => {
  const {
    reqData,
    dispatchAllBusData = () => {},
    dispatch = () => {},
  } = reqBody;
  try {
    const queryParams = {
      page: get(reqData, 'pagination.page', 1),
      perPage: get(reqData, 'pagination.perPage', 10),
    };

    const _classOpsService = classOpsService();
    const _userService = userService();
    const _centreService = centreSerivce();
    const body = {
      bus_entry_id: get(reqData, 'busEntryID'),
      centre_id: get(reqData, 'centreID'),
      ...(get(reqData, 'direction') && {
        direction: get(reqData, 'direction') ? 1 : 2,
      }),
      status: get(reqData, 'status'),
      year: get(reqData, 'year'),
    };

    const fullApiUrl = `${LIST_ALL_BUS_ENTRIES}?${new URLSearchParams(
      queryParams
    ).toString()}`;
    const res = await _classOpsService.post(fullApiUrl, body);
    const resp = get(res, 'data', {});

    if (get(resp, 'totalCount', 0) === 0) {
      const emptyData = {
        data: {
          listAllBusEntries: {
            data: [],
            totalCount: 0,
          },
        },
      };
      dispatchAllBusData(dispatch, false, emptyData);
      return emptyData;
    }

    const busDriverIds = get(resp, 'data', []).map(dt =>
      get(dt, 'bus_entry.fk_bus_driver')
    );
    const fkDriverIds = compact(uniq(busDriverIds));
    const busAttendantIds = get(resp, 'data', []).map(dt =>
      get(dt, 'bus_entry.fk_bus_attendant')
    );
    const fkAttendantIds = compact(uniq(busAttendantIds));
    const centerIds = get(resp, 'data', []).map(dt => get(dt, 'bus.fk_centre'));
    const fkCenterIds = compact(uniq(centerIds));

    const [busDrivers, busAttendants, centres] = await Promise.all([
      get(fkDriverIds, 'length')
        ? _userService
            .post(...GET_BY_IDS(fkDriverIds))
            .then(retrieveListOrObject)
            .catch(() => [])
        : Promise.resolve([]), // busUserByFkBusDriver
      get(fkAttendantIds, 'length')
        ? _userService
            .post(...GET_BY_IDS(fkAttendantIds))
            .then(retrieveListOrObject)
            .catch(() => [])
        : Promise.resolve([]), // busUserByFkBusAttendant
      get(fkCenterIds, 'length')
        ? _centreService
            .post(...GET_CENTRE_BY_IDS(fkCenterIds))
            .then(retrieveListOrObject)
            .catch(() => [])
        : Promise.resolve([]), // centres by ids
    ]);

    const busResp = transformListAllBusData(
      resp,
      busDrivers,
      busAttendants,
      centres
    );
    dispatchAllBusData(dispatch, false, busResp);
    return busResp;
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      data: undefined,
      error: get(ex, 'message', DEFAULT_ERROR),
    };
  }
};

export const msCreateBusRoute = async (_, reqBody) => {
  const { reqData } = reqBody;
  try {
    const body = {
      attendant: {
        firstname: get(reqData, 'Attendant.firstname'),
        lastname: get(reqData, 'Attendant.lastname'),
        fk_centre: get(reqData, 'fkCentre'),
        fk_school: get(reqData, 'fkSchool'),
        is_attendant: true,
        mobile_phone: get(reqData, 'Attendant.mobilePhone'),
        mobile_phone_country_code: get(
          reqData,
          'Attendant.mobilePhoneCountryCode'
        ),
      },
      bus: {
        plate_number: get(reqData, 'Bus.plateNumber'),
        fk_centre: get(reqData, 'fkCentre'),
        fk_school: get(reqData, 'fkSchool'),
      },
      bus_entry_create: {
        bus_company: get(reqData, 'BusEntry.busCompany'),
        direction: get(reqData, 'BusEntry.direction', '') === 'arrival' ? 0 : 1,
        label: get(reqData, 'BusEntry.label'),
        status: get(reqData, 'BusEntry.status', false),
        year_time: get(reqData, 'BusEntry.yearTime'),
        fk_centre: get(reqData, 'fkCentre'),
      },
      driver: {
        firstname: get(reqData, 'Driver.firstname'),
        lastname: get(reqData, 'Driver.lastname'),
        is_attendant: false,
        mobile_phone: get(reqData, 'Driver.mobilePhone'),
        mobile_phone_country_code: get(
          reqData,
          'Driver.mobilePhoneCountryCode'
        ),
        fk_centre: get(reqData, 'fkCentre'),
        fk_school: get(reqData, 'fkSchool'),
      },
      fk_centre: get(reqData, 'fkCentre'),
      fk_school: get(reqData, 'fkSchool'),
    };
    const resp = await classOpsService().post(CREATE_BUS_ROUTE, body);
    return {
      data: {
        insertBusRoute: {
          ID: get(resp, 'data.id', null),
        },
      },
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      data: undefined,
      error: get(ex, 'message', DEFAULT_ERROR),
    };
  }
};

export const msUpdateBusRoute = async (_, reqBody) => {
  const { reqData } = reqBody;
  try {
    const body = {
      attendant: {
        firstname: get(reqData, 'Attendant.firstname'),
        lastname: get(reqData, 'Attendant.lastname'),
        fk_centre: get(reqData, 'fkCentre'),
        fk_school: get(reqData, 'fkSchool'),
        is_attendant: true,
        mobile_phone: get(reqData, 'Attendant.mobilePhone'),
        mobile_phone_country_code: get(
          reqData,
          'Attendant.mobilePhoneCountryCode'
        ),
      },
      bus: {
        plate_number: get(reqData, 'Bus.plateNumber'),
        fk_centre: get(reqData, 'fkCentre'),
        fk_school: get(reqData, 'fkSchool'),
      },
      bus_entry_create: {
        bus_company: get(reqData, 'BusEntry.busCompany'),
        direction: get(reqData, 'BusEntry.direction', '') === 'arrival' ? 0 : 1,
        label: get(reqData, 'BusEntry.label'),
        status: get(reqData, 'BusEntry.status', false),
        year_time: get(reqData, 'BusEntry.yearTime'),
        fk_centre: get(reqData, 'fkCentre'),
      },
      driver: {
        firstname: get(reqData, 'Driver.firstname'),
        lastname: get(reqData, 'Driver.lastname'),
        is_attendant: false,
        mobile_phone: get(reqData, 'Driver.mobilePhone'),
        mobile_phone_country_code: get(
          reqData,
          'Driver.mobilePhoneCountryCode'
        ),
        fk_centre: get(reqData, 'fkCentre'),
        fk_school: get(reqData, 'fkSchool'),
      },
      fk_centre: get(reqData, 'fkCentre'),
      fk_school: get(reqData, 'fkSchool'),
    };
    const resp = await classOpsService().put(UPDATE_BUS_ROUTE, body);
    return {
      data: {
        updateBusRoute: {
          ID: get(resp, 'data.id', null),
        },
      },
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      data: undefined,
      error: get(ex, 'message', DEFAULT_ERROR),
    };
  }
};

export const msListAllBusByIds = async ids => {
  const _classOpsService = classOpsService();
  const response = await _classOpsService.post(GET_BUS_BY_IDS, {
    bus_ids: ids,
  });
  return response.data?.data?.map(({ id, plate_number }) => ({
    ID: id,
    plateNumber: plate_number,
  }));
};

export const msListAllBusChildren = async (_, reqBody) => {
  const {
    reqData,
    dispatchAllBusChildren = () => {},
    dispatch = () => {},
  } = reqBody;
  try {
    const queryParams = {
      bus_id: get(reqData, 'busID'),
      centre_id: get(reqData, 'centreID'),
      page: get(reqData, 'pagination.page', 1),
      perPage: get(reqData, 'pagination.perPage', 10),
    };
    if (reqData.levelID) {
      queryParams.level_id = reqData.levelID;
    }
    if (reqData.classID) {
      queryParams.class_id = reqData.classID;
    }

    const _classOpsService = classOpsService();
    const _childService = childService();
    const _classService = classService();

    const fullApiUrl = `${LIST_ALL_BUS_CHILDREN}?${new URLSearchParams(
      queryParams
    ).toString()}`;
    const res = await _classOpsService.get(fullApiUrl);
    const resp = get(res, 'data', {});

    if (get(resp, 'totalCount', 0) === 0) {
      const emptyData = {
        data: {
          listAllBusChildren: {
            data: [],
            totalCount: 0,
          },
        },
      };
      dispatchAllBusChildren(dispatch, false, emptyData);
      return emptyData;
    }

    const busIds = get(resp, 'data', []).map(dt => get(dt, 'fk_bus'));
    const fkBusIds = compact(uniq(busIds));
    const childIds = get(resp, 'data', []).map(dt => get(dt, 'fk_child'));
    const fkChildIds = compact(uniq(childIds));

    const [buses, children, currentClasses, childLevels] = await Promise.all([
      fkBusIds ? msListAllBusByIds(fkBusIds) : Promise.resolve([]), // busUserByFkBusDriver
      fkChildIds
        ? _childService
            .post(...GET_CHILD_BY_IDS(fkChildIds))
            .then(retrieveListOrObject)
            .catch(() => [])
        : Promise.resolve([]),
      _childService
        .post(...GET_CHILD_CURRENT_CLASS_BY_IDS(childIds))
        .then(res => retrieveListOrObject(res, false))
        .catch(() => ({})),
      _childService
        .post(...GET_LEVEL_BY_CHILD_IDS(childIds))
        .then(retrieveListOrObject)
        .catch(() => []),
    ]);

    const classIds = uniq([
      ...Object.entries(currentClasses).map(([, _class]) => _class?.id),
    ])?.filter(f => !!f);

    const classes = await (classIds.length
      ? _classService
          .post(...GET_CLASS_BY_IDS(classIds))
          .then(retrieveListOrObject)
          .catch(() => [])
      : Promise.resolve([])); // classes by ids

    const busResp = {
      data: {
        listAllBusChildren: {
          totalCount: get(resp, 'totalCount', 0),
          data: get(resp, 'data', []).map(data => {
            const { fk_bus, fk_child } = data;
            const childData = children.find(({ id }) => id === fk_child);
            let child;
            if (childData) {
              child = {
                ...retrieveObjectMapper(childData, [
                  'ID:id',
                  'firstname',
                  'lastname',
                  'image_key',
                  'imageKeyPresignedURL:image_key_presigned_url',
                  'created_at',
                  'updated_at',
                  'birthCertificate:birth_certificate',
                ]),
                currentClass: (() => {
                  const _class = retrieveDataMapper(
                    currentClasses,
                    childData?.id,
                    ['fkClass:id']
                  );
                  return {
                    ..._class,
                    class: {
                      ...retrieveDataMapper(classes, _class?.fkClass, [
                        'id',
                        'label',
                      ]),
                    },
                  };
                })(),
                currentLevel: (() => {
                  const childLevel = retrieveDataMapper(
                    childLevels,
                    childData?.id,
                    ['fk_level', 'fk_centre'],
                    'fk_child'
                  );
                  if (!childLevel) return null;
                  return childLevel;
                })(),
              };
            }
            return {
              ...retrieveObjectMapper(data, [
                'ID:id',
                'active',
                'fkBus:fk_bus',
                'fkChild:fk_child',
                'locationText:location_text',
                'remarks',
                'serviceEndDate:service_end_date',
                'serviceStartDate:service_start_date',
              ]),
              child,
              bus: buses.find(({ ID }) => ID === fk_bus),
            };
          }),
        },
      },
    };
    dispatchAllBusChildren(dispatch, false, busResp);
    return busResp;
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      data: undefined,
      error: get(ex, 'message', DEFAULT_ERROR),
    };
  }
};

export const msAssignChildToBus = async (_, reqBody) => {
  const { reqData } = reqBody;
  try {
    const body = {
      fk_child: reqData?.fkChild,
      fk_bus: reqData?.fkBus,
      service_start_date: reqData?.serviceStartDate,
      service_end_date: reqData?.serviceEndDate,
      location_text: reqData?.locationText,
      remarks: reqData?.remarks,
    };
    const resp = await classOpsService().post(CREATE_BUS_CHILD, body);
    return {
      data: {
        assignChildToBus: {
          ID: resp?.data?.id,
        },
      },
      success: true,
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      data: undefined,
      error: get(ex, 'message', DEFAULT_ERROR),
    };
  }
};

export const msDeleteChildFromBus = async (_, { id }) => {
  try {
    await classOpsService().delete(DELETE_CHID_FROM_BUS(id));
    return {
      data: {
        deleteBusChildById: true,
      },
      success: true,
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      data: undefined,
      error: get(ex, 'message', DEFAULT_ERROR),
    };
  }
};

export const msFetchAllChildrenForBus = async (_, reqBody) => {
  const {
    reqData,
    dispatch = () => {},
    dispatchFetchChildrenForBus = () => {},
  } = reqBody;
  try {
    const response = await classOpsService().get(GET_UNASSIGN_CHILDREN, {
      centre_id: reqData?.centreID,
    });
    const result = {
      data: {
        findAllChildrenNotAssignedToBus: {
          totalCount: response?.data?.totalCount,
          data: response?.data?.data?.map(data => ({
            // Todo: seems the mapping is not correct
            ...retrieveObjectMapper(data, [
              'ID:id',
              'firstname',
              'lastname',
              'birthCertificate',
              'imageKey',
              'imageKeyPresignedURL',
            ]),
          })),
        },
      },
      success: true,
    };
    dispatchFetchChildrenForBus(dispatch, false, result);
    return result;
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    dispatchFetchChildrenForBus(dispatch, false, null, [ex?.message]);
    return {
      data: undefined,
      error: get(ex, 'message', DEFAULT_ERROR),
    };
  }
};

export const msDeleteBusEntry = async (_, { reqData }) => {
  try {
    await classOpsService().delete(DELETE_BUS_ENTRY(reqData?.ID));
    return {
      data: {
        deleteBusEntry: true,
      },
      success: true,
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      data: undefined,
      error: get(ex, 'message', DEFAULT_ERROR),
    };
  }
};

export default {
  transformListAllBusData,
  msListAllBusData,
  msCreateBusRoute,
  msUpdateBusRoute,
  msListAllBusByIds,
  msListAllBusChildren,
  msAssignChildToBus,
  msDeleteChildFromBus,
  msFetchAllChildrenForBus,
  msDeleteBusEntry,
};
