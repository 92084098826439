/* eslint-disable camelcase */
import { get, compact, uniq } from 'lodash';
import DatadogHandler from 'utils/datadog';
import {
  MS_PHASE_2D_CLASS_OPERATIONS_ENDPOINTS,
  classOpsService,
} from '../../../../utils/msEndpointConstants';
import { retrieveSortForMS, retrieveObjectMapper } from '../../../../utils';
import { DEFAULT_ERROR } from '../../../../utils/constants';

const {
  GET_CLASS_SECTIONS,
  CREATE_CLASS_SECTION,
  UPDATE_CLASS_SECTION,
  DELETE_CLASS_SECTION,
  RE_ARRANGE_CLASS_SECTIONS,
  RE_ARRANGE_CLASS_SECTION_RESOURCES,
  GET_CLASS_RESOURCES,
  CREATE_CLASS_RESOURCE,
  UPDATE_CLASS_RESOURCE,
  GET_CLASS_RESOURCE_UPLOAD_URL,
  DELETE_CLASS_RESOURCE,
} = MS_PHASE_2D_CLASS_OPERATIONS_ENDPOINTS.RESOURCES;

export const transformClassResourceData = (resp, resources) => {
  const data = get(resp, 'data', {});
  return {
    totalCount: get(data, 'totalCount', 0),
    data: get(data, 'data', []).map(dt => ({
      ...retrieveObjectMapper(dt, ['ID:id', 'title', 'pinned', 'index']),
      classResources: {
        totalCount: resources?.length || 0,
        data:
          resources
            ?.filter(({ fk_class_resource }) => fk_class_resource === dt.id)
            ?.map(resource => ({
              ...retrieveObjectMapper(resource, [
                'ID:id',
                'title',
                'pinned',
                'index',
                'uRL:url',
                'preSignedUrl:url_presigned_url',
                'description',
                'filename',
              ]),
            })) || [],
      },
    })),
  };
};

export const msFetchClassResources = async (_, reqBody = {}) => {
  const {
    reqData,
    dispatchClassResources = () => {},
    dispatch = () => {},
  } = reqBody;
  try {
    const _classOpsService = classOpsService();
    const queryParams = {
      classID: get(reqData, 'fkClass'),
      ...retrieveSortForMS(['-pinned', 'index', '-updated_at'], 'customSort'),
    };
    const fullApiUrl = `${GET_CLASS_SECTIONS}?${new URLSearchParams(
      queryParams
    ).toString()}`;
    const res = await _classOpsService.get(fullApiUrl);
    const resResources = await _classOpsService.get(
      `${GET_CLASS_RESOURCES}?${new URLSearchParams({
        classId: get(reqData, 'fkClass'),
        ...retrieveSortForMS(['-pinned', 'index', '-updated_at'], 'customSort'),
      }).toString()}`
    );
    const resp = transformClassResourceData(res, resResources?.data?.data);

    if (get(resp, 'data.length', 0) === 0) {
      const emptyData = {
        findAllClassResources: {
          data: [],
          totalCount: 0,
        },
      };
      dispatchClassResources(dispatch, false, emptyData);
      return emptyData;
    }

    const result = {
      findAllClassResources: resp,
    };
    dispatchClassResources(dispatch, false, result);
    return result;
  } catch (e) {
    DatadogHandler.addError(e);
    DatadogHandler.sendLog(e, {}, 'error');
    const emptyData = {
      findAllClassResources: {
        data: [],
        totalCount: 0,
      },
    };
    dispatchClassResources(dispatch, false, emptyData);
    return emptyData;
  }
};

export const msAddorUpdateSection = async (_, reqBody) => {
  try {
    const reqData = reqBody?.reqData || reqBody;
    const isResource = reqBody?.isResource;
    const id = reqData?.classResourceID;
    let res;
    if (reqData?.dto?.FkClassResourceSection || isResource) {
      // resource
      const body = {
        section_id: reqData?.dto?.FkClassResourceSection,
        title: reqData?.dto?.Title,
        url: reqData?.url || reqData?.dto?.URL,
        filename: reqData?.fileName || reqData?.dto?.fileName,
      };
      if (id) {
        res = await classOpsService().put(UPDATE_CLASS_RESOURCE(id), body);
      } else {
        res = await classOpsService().post(CREATE_CLASS_RESOURCE, body);
      }
    } else {
      // section
      const body = {
        class_id: reqData?.dto?.IDClass,
        pinned: reqData?.dto?.Pinned,
        title: reqData?.dto?.Title,
      };
      if (id) {
        res = await classOpsService().put(UPDATE_CLASS_SECTION(id), body);
      } else {
        res = await classOpsService().post(CREATE_CLASS_SECTION, body);
      }
    }

    return {
      data: {
        createOrUpdateClassResource: {
          ID: get(res, 'data.id'),
          title: get(res, 'data.title'),
          fkClassResource: get(res, 'data.fk_class_resource', null),
          filename: get(res, 'data.filename', null),
        },
      },
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export const msDeleteClassSection = async (_, reqBody) => {
  try {
    const sectionId = get(reqBody, 'classResourceID');
    await classOpsService().delete(DELETE_CLASS_SECTION(sectionId));
    return {
      data: {
        deleteClassSectionOrResource: true,
      },
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export const msDeleteClassResource = async (_, reqBody) => {
  try {
    const sectionId = get(reqBody, 'classResourceID');
    await classOpsService().delete(DELETE_CLASS_RESOURCE(sectionId));
    return {
      data: {
        deleteClassSectionOrResource: true,
      },
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export const reArrangeClassSections = async reqData => {
  try {
    const sectionIds = compact(
      uniq(get(reqData, 'data').map(dt => get(dt, 'IDSection')))
    );
    const body = {
      class_id: get(reqData, 'classID'),
      section_ids: sectionIds,
    };
    await classOpsService().post(RE_ARRANGE_CLASS_SECTIONS, body);

    return {
      data: {
        updateClassResourceOrder: true,
      },
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export const reArrangeClassSectionResources = async reqData => {
  try {
    const body = {
      class_id: get(reqData, 'classID'),
      resources: get(reqData, 'data', []).reduce((acc, section) => {
        section.Resources.forEach((resource, index) => {
          acc.push({
            id: get(resource, 'IDResource'),
            index,
            section_id: get(section, 'IDSection'),
          });
        });
        return acc;
      }, []),
    };
    await classOpsService().post(RE_ARRANGE_CLASS_SECTION_RESOURCES, body);
    return {
      data: {
        updateClassResourceOrder: true,
      },
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export const msUpdateClassSectionResourceOrder = async (_, reqBody) => {
  if (get(reqBody, 'sortType') === 'section')
    return reArrangeClassSections(reqBody);
  if (get(reqBody, 'sortType') === 'resource')
    return reArrangeClassSectionResources(reqBody);
};

export const msGetResourceFileUploadUrl = async (_, { reqData }) => {
  try {
    let file = reqData?.file;
    if (file) {
      file = {
        file_name: file?.fileName,
        mime_type: file?.mimeType,
        file_path: file?.filePath,
        file_size: file?.fileSize,
      };
    }
    const response = await classOpsService().post(
      GET_CLASS_RESOURCE_UPLOAD_URL(reqData?.classResourceID),
      file
    );
    return {
      data: {
        getClassResourceFileUploadURL: {
          Url: response.data?.url,
          Key: response.data?.key,
          Filename: response.data?.filename,
          FileSize: file?.fileSize,
        },
      },
      success: true,
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export default {
  transformClassResourceData,
  msFetchClassResources,
  msAddorUpdateSection,
  msDeleteClassSection,
  msDeleteClassResource,
  reArrangeClassSections,
  reArrangeClassSectionResources,
  msUpdateClassSectionResourceOrder,
  msGetResourceFileUploadUrl,
};
